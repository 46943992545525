document.addEventListener('turbolinks:load', (event) => {
  const fileInputs = document.querySelectorAll('input[type="file"]')
  if (!fileInputs || fileInputs.length == 0) { return }

  fileInputs.forEach(function(fileInput) {
    fileInput.addEventListener('change', function(e) {
      var label = e.target.parentNode.querySelector('div')

      label.classList.remove('bg-blue-500')
      label.classList.remove('hover:bg-blue-600')
      label.classList.add('bg-green-600')
      label.classList.add('hover:bg-green-700')

      label.innerHTML = "<i class='fas fa-check'></i>"
    })
  })

  addEventListener('direct-upload:initialize', event => {
    const { target, detail } = event
    const { id, file } = detail

    fileInputs.forEach(function(fileInput) {
      fileInput.classList.add('hidden')
    })

    target.insertAdjacentHTML('beforebegin', `
      <div id="direct-upload-${id}"
           class="block p-2 mt-2 bg-white rounded shadow relative">
        <div id="direct-upload-progress-${id}"
             class="absolute rounded top-0 left-0 bottom-0 opacity-25 bg-orange-500"
             style="width: 0%"></div>
        <span id="direct-upload-label-${id}" class="text-gray-900">
          ${file.name}
        </span>
      </div>
    `)
  })

  addEventListener('direct-upload:start', event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)

    element.classList.remove('opacity-75')
  })

  addEventListener('direct-upload:progress', event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)

    progressElement.style.width = `${progress}%`
  })

  addEventListener('direct-upload:error', event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)

    element.classList.add('border-red-800')
    element.setAttribute('title', error)
  })

  addEventListener('direct-upload:end', event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-label-${id}`)

    element.innerHTML = "<i class='fas fa-spinner fa-spin' title='Processing'></i> Processing"
  })
})
