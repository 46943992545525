const Color = require('./color.js'),
      Marker = require('./marker.js'),
      Rails = require('@rails/ujs'),
      Popup = require('./popup.js'),
      Symbol = require('./symbol.js')

function addTracks(map, layers, popup, tracks, color) {
  var features = tracks.reduce(function(features, track) {
    var name = track[0],
        distance = track[1],
        coordinates = track[2]

    if (distance > 0) { name += ' (' + distance + ' mi)' }

    return features.concat({
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': coordinates
      },
      'properties': {
        'name': name,
        'color': (color || Color.random())
      }
    })
  }, [])

  layers.push({
    'id': 'tracks',
    'type': 'line',
    'layout': {
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-width': 6,
      'line-color': ['get', 'color']
    },
    'source': {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': features
      }
    }
  })

  map.on('click', 'tracks', function (e) {
    popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
         .setHTML(e.features[0].properties.name)
         .addTo(map)
  })

  map.on('mouseenter', 'tracks', function (e) {
    map.getCanvas().style.cursor = 'pointer'
    popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
         .setHTML(e.features[0].properties.name)
         .addTo(map)
  })

  map.on('mouseleave', 'tracks', function (e) {
    map.getCanvas().style.cursor = ''
    popup.remove()
  })
}

function addWaypoints(map, layers, popup, waypoints) {
  if(waypoints && waypoints.length > 0) {
    var features = waypoints.reduce(function(features, waypoint) {
      var name = waypoint[0],
          symbol = Symbol.forWaypoint(waypoint[1]),
          coordinates = waypoint[2]

      return features.concat({
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': coordinates
        },
        'properties': {
          'name': name,
          'symbol': symbol
        }
      })
    }, [])

    layers.push({
      'id': 'waypoints',
      'type': 'symbol',
      'source': {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features,
        }
      },
      'layout': {
        'text-field': ['get', 'name'],
        'text-offset': [0, 0.7],
        'text-anchor': 'top',
        'text-size': 14,
        'text-font': ['literal', ['Source Code Pro Semibold']],
        'icon-image': ['get', 'symbol'],
        'icon-size': 0.5,
        'icon-allow-overlap': true
      },
      'paint': {
        'text-color': Color.darkGray,
        'text-halo-color': Color.gray,
        'text-halo-width': 1.5,
        'text-halo-blur': 1,
        'icon-opacity': 0.75
      }
    })

    map.on('click', 'waypoints', function (e) {
      popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
           .setHTML(e.features[0].properties.name)
           .addTo(map)
    })

    map.on('mouseenter', 'waypoints', function (e) {
      map.getCanvas().style.cursor = 'pointer'
      popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
           .setHTML(e.features[0].properties.name)
           .addTo(map)
    })

    map.on('mouseleave', 'waypoints', function (e) {
      map.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
}

function addPhotos(map, layers, photos, options) {
  if (photos && photos.length > 0) {
    photos.forEach(function(photo) {
      var largeURL = photo[0],
        smallURL = photo[1],
        coordinates = photo[2],
        updateURL = photo[3],
        marker = Marker.forPhoto(smallURL, options.draggable)

      if (!coordinates[0] || !coordinates[1]) {
        coordinates = [
          options.bounds.getCenter().lng + (Math.random() / 100),
          options.bounds.getCenter().lat + (Math.random() / 100)
        ]
      }

      marker
        .setLngLat(coordinates)
        .setPopup(Popup.forPhoto(largeURL))
        .addTo(map)

      marker.on('dragend', function() {
        var lngLat = marker.getLngLat(),
            params = ['latitude=' + lngLat.lat, 'longitude=' + lngLat.lng]

        Rails.ajax({
          type: 'PUT',
          url: [updateURL].concat(params).join('&')
        })
      })
    })
  }
}

module.exports = {
  addTracks: addTracks,
  addWaypoints: addWaypoints,
  addPhotos: addPhotos
}
