document.addEventListener('turbolinks:load', (event) => {
  var clipboard = new ClipboardJS('.js-clipboard')

  clipboard.on('success', function(e) {
    var originalText = e.trigger.innerHTML

    e.trigger.innerHTML = 'Copied!'

    setTimeout(function() {
      e.trigger.innerHTML = originalText
    }, 500)
  })
})
