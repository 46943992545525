document.addEventListener('turbolinks:load', (event) => {
  var menus = document.querySelectorAll('.js-menu'),
      dropdowns = [],
      buttons = []
  if (!menus || menus.length == 0) { return }

  function hideDropdown(d) { d.classList.add('hidden') }
  function hideMenu(m) { m.classList.remove('opacity-100') }
  function hideButton(b) { b[1].forEach(function(style) { b[0].classList.remove(style) }) }

  function hide() {
    menus.forEach(function(m) { hideMenu(m) })
    dropdowns.forEach(function(d) { hideDropdown(d) })
    buttons.forEach(function(b) { hideButton(b) })
  }

  function show(menu, dropdown, button, selectedStyles) {
    menu.classList.add('opacity-100')
    dropdown.classList.remove('hidden')
    selectedStyles.forEach(function(style) { button.classList.add(style) })
  }

  menus.forEach(function(menu) {
    var button = menu.querySelector('.js-menu-button'),
        dropdown = menu.querySelector('.js-menu-dropdown'),
        selectedStyles = JSON.parse(menu.getAttribute('data-menu-selected-styles')) || []

    buttons.push([button, selectedStyles])
    dropdowns.push(dropdown)

    button.addEventListener('click', function(e) {
      if(dropdown.classList.contains('hidden')) {
        hide()
        show(menu, dropdown, button, selectedStyles)
      } else {
        hide()
      }
    })
  })
})
