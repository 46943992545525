const MapBox = require('mapbox-gl')

function forPhoto(url, draggable) {
  var container = document.createElement('div'),
      img = document.createElement('img')

  img.src = url
  img.alt = ''
  img.width = 50

  container.className = 'p-1 bg-white shadow cursor-pointer'
  container.appendChild(img)

  return new MapBox.Marker(container, { draggable: draggable })
}

module.exports = {
  forPhoto: forPhoto
}
