const color = [
  '#38B2AC', '#4299E1', '#48BB78', '#4FD1C5', '#63B3ED',
  '#667EEA', '#68D391', '#7F9CF5', '#9F7AEA', '#B794F4',
  '#ED64A6', '#ED8936', '#F56565', '#F687B3', '#FC8181',
  '#D53F8C', '#805AD5', '#5A67D8', '#3182CE', '#319795',
  '#38A169', '#DD6B20', '#E53E3E'
]

function random() {
  return color[Math.floor(Math.random() * color.length)]
}

module.exports = {
  random: random,
  red: '#F56565',
  white: '#FFFFFF',
  gray: '#EEEEEE',
  darkGray: '#1A202C',
  blue: '#5A67D8'
}
