const MapBox = require('mapbox-gl')

function create(options) {
  return new MapBox.Popup(
    Object.assign({},{
      closeOnClick: true,
      closeButton: false,
      className: 'text-gray-900 font-mono text-base'
    }, options)
  )
}

function forPhoto(url) {
  return create({ offset: 20 })
    .setHTML("<div class='select-none'>" +
      "<img src='" + url + "' width='500' alt='' draggable='false'>" +
    "</div>")
}

module.exports = {
  create: create,
  forPhoto: forPhoto
}
