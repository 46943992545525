const symbols = {
  'airport': 'airport',
  'bar': 'bar',
  'bike-trail': 'bike-trail',
  'campground': 'campground',
  'car': 'car',
  'city-small': 'city-small',
  'controlled-area': 'controlled-area',
  'convenience-store': 'convenience-store',
  'crossing': 'crossing',
  'danger-area': 'skull-and-crossbones',
  'fast-food': 'restaurant',
  'flag': 'flag',
  'flag-blue': 'flag',
  'flag-green': 'flag',
  'flag-red': 'flag',
  'gas-station': 'gas-station',
  'geocache': 'geocache',
  'information': 'information',
  'lodge': 'lodge',
  'lodging': 'lodge',
  'mine': 'mine',
  'museum': 'museum',
  'parachute-area': 'parachute-area',
  'park': 'park',
  'parking-area': 'parking-area',
  'picnic-area': 'restaurant',
  'residence': 'lodge',
  'restaurant': 'restaurant',
  'scenic-area': 'scenic-area',
  'shopping-center': 'convenience-store',
  'skull-and-crossbones': 'skull-and-crossbones',
  'summit': 'summit',
  'toll-booth': 'toll-booth',
  'trail-head': 'bike-trail',
  'tunnel': 'tunnel'
}

function forWaypoint(symbol) {
  if (symbol) {
    symbol = symbols[
      symbol.toLowerCase().replace(/[^a-z\s]/g, '').replace(/\s+/g, '-')
    ]
  }

  return symbol || 'waypoint'
}

function loadImage(map, id) {
  map.loadImage('/assets/waypoints/' + id + '.png', function(error, image) {
    if (error) { throw error }
    if (!map.hasImage(id)) { map.addImage(id, image) }
  })
}

module.exports = {
  forWaypoint: forWaypoint,
  loadImage: loadImage
}
