document.addEventListener('turbolinks:load', (event) => {
  const button = document.querySelector('.js-new-subscription')
  if (!button) { return }

  const sessionId = button.getAttribute('data-session-id'),
        stripeKey = button.getAttribute('data-stripe-key')
  if (!sessionId || !stripeKey) { return }

  const stripe = Stripe(stripeKey)

  button.addEventListener('click', (_event) => {
    stripe.redirectToCheckout({
      sessionId: sessionId
    }).then(function (result) {
      // There's a network error...
      // Maybe print result.error.message?
    })
  })
})
