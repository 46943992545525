const Rails = require('@rails/ujs'),
      MapBox = require('mapbox-gl'),
      MapBoxDraw = require('@mapbox/mapbox-gl-draw'),
      MapBoxSearch = require('@mapbox/mapbox-gl-geocoder'),
      Color = require('./color.js'),
      Layer = require('./layer.js'),
      Popup = require('./popup.js'),
      Symbol = require('./symbol.js')

document.addEventListener('turbolinks:load', (event) => {
  const mapContainer = document.querySelector('#map'),
        mapStyles = document.querySelectorAll('.js-map-style')
  if (!mapContainer || !mapStyles) { return }

  var tracks = JSON.parse(mapContainer.getAttribute('data-coordinates')),
      accessToken = mapContainer.getAttribute('data-access-token'),
      style = mapContainer.getAttribute('data-style'),
      routepoints = JSON.parse(mapContainer.getAttribute('data-routepoints')),
      waypoints = JSON.parse(mapContainer.getAttribute('data-waypoints')),
      photos = JSON.parse(mapContainer.getAttribute('data-photos')),
      bounds = JSON.parse(mapContainer.getAttribute('data-bounds')),
      options = JSON.parse(mapContainer.getAttribute('data-options'))
  if (!accessToken || !style || !bounds) { return }

  MapBox.accessToken = accessToken
  bounds = new MapBox.LngLatBounds(bounds)

  var map = new MapBox.Map({
    container: 'map',
    interactive: options.interactive,
    customAttribution: 'mytracks.co',
    style: style,
    bounds: bounds,
    fitBoundsOptions: {
      padding: {
        top: options.padding.top,
        right: options.padding.right,
        bottom: options.padding.bottom,
        left: options.padding.left,
      }
    }
  })

  var popup = Popup.create(),
      layers = [],
      draw

  function updateRoute(e, resetBounds) {
    var url = window.location.href.replace(/\/[A-Za-z]*$/, '')

    Rails.ajax({
      type: 'PUT',
      url: url,
      beforeSend: function(xhr, options) {
        xhr.setRequestHeader(
          'Content-Type', 'application/json; charset=UTF-8'
        )

        options.data = JSON.stringify({
          route: { points: e.features[0].geometry.coordinates }
        })

        return true
      },

      success: function(response) {
        tracks = response.coordinates
        bounds = new MapBox.LngLatBounds(response.bounds)

        updateTrackLayers(Color.blue, resetBounds)
        removeLayersFromMap()
        addLayersToMap()
      }
    })
  }

  function updateTrackLayers(color, resetBounds) {
    layers = layers.reduce(function(layers, layer) {
      if (layer.id == 'tracks') {
        return layers
      } else {
        return layers.push(layer)
      }
    }, [])

    Layer.addTracks(map, layers, popup, tracks, color)

    if (resetBounds) { fitBounds() }
  }

  function removeLayersFromMap() {
    layers.forEach(function(layer) {
      map.removeLayer(layer.id)
      map.removeSource(layer.id)
    })
  }

  function addLayersToMap() {
    layers.forEach(function(layer) {
      map.addLayer(layer)
    })
  }

  function fitBounds() {
    map.fitBounds(bounds, {
      padding: {
        top: options.padding.top,
        right: options.padding.right,
        bottom: options.padding.bottom,
        left: options.padding.left,
      },
      duration: 150
    })
  }

  map.on('styleimagemissing', function(e) {
    Symbol.loadImage(map, e.id)
  })

  map.on('style.load', function() {
    addLayersToMap()
  })

  map.on('load', function() {
    Layer.addTracks(map, layers, popup, tracks)
    Layer.addWaypoints(map, layers, popup, waypoints)
    Layer.addPhotos(map, layers, photos, {
      bounds: bounds,
      draggable: options.draggablePhotos
    })

    if (options.showDrawControls && routepoints && routepoints.length > 0) {
      updateTrackLayers(Color.blue, false)

      draw.add({
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': routepoints
        }
      })
    }

    addLayersToMap()
  })

  if (options.showGeolocateControl) {
    map.addControl(
      new MapBox.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
    )
  }

  if (options.showSearchControl) {
    map.addControl(
      new MapBoxSearch({
        accessToken: accessToken,
        mapboxgl: MapBox,
        placeholder: 'Search'
      }), 'top-left'
    )
  }

  if (options.showDrawControls) {
    draw = new MapBoxDraw({
      boxSelect: false,
      styles: [
        {
          'id': 'gl-draw-line',
          'type': 'line',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': Color.red,
            'line-width': 6,
            'line-opacity': 0.75
          }
        },
        {
          'id': 'gl-draw-polygon-and-line-vertex-halo-active',
          'type': 'circle',
          'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
          'paint': {
            'circle-radius': 12,
            'circle-color': Color.white,
            'circle-opacity': 0.5
          }
        },
        {
          'id': 'gl-draw-polygon-and-line-vertex-active',
          'type': 'circle',
          'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
          'paint': {
            'circle-radius': 10,
            'circle-color': Color.red
          }
        }
      ]
    })

    map.addControl(draw)
    map.on('draw.update', updateRoute)
    map.on('draw.create', function(e) {
      draw.getAll().features.forEach(function(other) {
        if (other.id != e.features[0].id) { draw.delete(other.id) }
      })

      updateRoute(e, true)
    })
  }

  mapStyles.forEach(function(button, _index, _arr) {
    button.addEventListener('click', function(e) {
      var newStyle = e.target.getAttribute('data-style')

      if (newStyle != style) {
        style = newStyle
        map.setStyle(style)
        mapStyles.forEach(function(s) { s.classList.remove('bg-gray-300') })
        e.target.classList.add('bg-gray-300')
      }
    })
  })

  map.on('click', function() {
    document.querySelectorAll('.js-menu').forEach(function(menu) {
      var dropdown = menu.querySelector('.js-menu-dropdown')

      if (!dropdown.classList.contains('hidden')) {
        dropdown.classList.add('hidden')
        menu.classList.remove('opacity-100')
      }
    })
  })
})
