var Tagify = require('@yaireo/tagify')

document.addEventListener('turbolinks:load', (event) => {
  var tagfield = document.querySelector('.js-tagify')
  if (!tagfield) { return }

  var tagify = new Tagify(tagfield, {
    delimiters: ',| '
  })
})
