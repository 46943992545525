var Fuse = require('fuse.js')

document.addEventListener('turbolinks:load', (event) => {
  var search = document.querySelector('.js-track-search'),
      results = document.querySelector('.js-track-search-results')
  if (!search || !results) { return }

  var tracks = JSON.parse(search.getAttribute('data-tracks'))
  if (!tracks) { return }

  var fuse = new Fuse(tracks, {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [ 'name' ]
  })

  function newTrackNode(id, name) {
    var container = document.createElement('div'),
        input = document.createElement('input'),
        label = document.createElement('label')

    input.setAttribute('type', 'checkbox')
    input.setAttribute('value', id)
    input.setAttribute('checked', true)
    input.setAttribute('name', 'group[track_ids][]')
    input.setAttribute('id', 'group_track_ids_' + id)

    label.setAttribute('class', 'ml-2 cursor-pointer')
    label.setAttribute('for', 'group_track_ids_' + id)
    label.innerHTML = name

    container.setAttribute('class', 'select-none p-2 bg-gray-200 mb-2 shadow w-full')
    container.appendChild(input)
    container.appendChild(label)

    return container
  }

  search.addEventListener('input', (e) => {
    results.innerHTML = fuse.search(search.value).reduce(function(html, track) {
      return html +
        "<div class='js-track-search-result cursor-pointer p-2 hover:bg-gray-100' " +
             "data-id='" + track.id + "' data-name='" + track.name + "'>" +
          track.name +
        "</div>\n"
    }, '')

    document.querySelectorAll('.js-track-search-result').forEach(function (result) {
      result.addEventListener('click', function(e) {
        var node = newTrackNode(
          e.target.getAttribute('data-id'),
          e.target.getAttribute('data-name'),
        )

        results.parentNode.insertBefore(node, results.nextSibling)

        search.value = ''
        results.innerHTML = ''
      })
    })
  })
})
