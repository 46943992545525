// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails stuff
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

// Use Tailwind
require('stylesheets/application.scss')

// Tracks map
require('map')

// Clipboard.js
require('clipboard')

// Dropdown menus (tracks, routes, map styles, etc)
require('dropdown')

// Stripe checkout
require('subscriptions')

// Form magic
require('forms/track_search')
require('forms/tags')
require('forms/uploads')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
